import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Button from '../../components/button'
import Container from '../../components/container'
import DemoForm from '../../components/demo_form'
import Link from '../../components/link'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import VideoModal from '../../components/video_modal'
import FordDirect from '../../images/FordDirect-ko.png'

const OemProgramPage = ({ data }) => {

  const content = data.contentfulOemProgram;
  const brand = content.brand;
  const logoSize = 100;

  const getImage = () => {
    const imageSource = (brand === 'FordDirect' ? FordDirect : 'https://media.roadster.com/makes/' + brand + '-ko.svg');
    return imageSource;
  }
 

  return (
    <Layout activeNav={true}>
      <Seo
        title={`OEM Partners | Roadster - ${brand}`}
        excludeTitleSuffix={true}
        description={content.metaDescription}
      />
      <div className='flex flex-col justify-center bg-cover bg-center text-white relative' style={{ minHeight: '84vh' }}>
        {content.heroImage &&
          <GatsbyImage
            image={content.heroImage.gatsbyImageData}
            className='absolute inset-0'
          />
        }
        <div className='bg-darken5 absolute inset-0'></div>
        <Container className='relative'>
          <div style={{ marginLeft: -20 }}>
            {brand === 'Jaguar' || brand === 'Land Rover' ?
              <div className='flex'>
                <img
                  src={`https://media.roadster.com/makes/Jaguar-ko.svg`}
                  width={logoSize}
                  height={logoSize}
                  alt=''
                  className='block'
                />
                <img
                  src={`https://media.roadster.com/makes/Land Rover-ko.svg`}
                  width={logoSize}
                  height={logoSize}
                  alt=''
                  className='block'
                />
              </div> :
              <img
                src={getImage()}
                width={logoSize}
                height={logoSize}
                alt=''
                className='block'
              />
            }
          </div>
          <h1 className='mt-0 sm:text-2xl lg:text-3xl font-semi-bold'>
            {content.title}
          </h1>
          {content.intro?.childMdx?.body &&
            <div className='sm:text-md' style={{ maxWidth: '56em' }}>
              <MDXRenderer children={content.intro.childMdx.body} />
            </div>
          }
          {content.enrollLink &&
            <div className='mt-3'>
              <Button
                href={content.enrollLink}
                variant='light'
                minWidth={true}
                rel='noopener noreferrer'
                target='_blank'
                children='Enroll'
              />
            </div>
          }
          {content.caseStudyLink &&
            <div className='mt-3'>
              <Link
                href={content.caseStudyLink}
                target='_blank'
                rel='noopener noreferrer'
                variant='underline'
                className='uppercase font-semi-bold'
                children='Download Case Study'
              />
            </div>
          }
        </Container>
      </div>
      <div className='Section Section--stripe leading-relaxed'>
        <StaticImage
          src='../../images/ES_Bg.jpg'
          placeholder='blurred'
          className='absolute top-0 left-0 w-full'
          alt=''
          quality={100}
        />
        <Container className='text-center relative'>
          <h2 className='text-2xl lg:text-3xl mt-0 text-center font-semi-bold'>
            Express Storefront<sup className='tm font-light'>®</sup>
          </h2>
          <div className='text-md'>
            Commerce solutions for today’s modern dealership
          </div>
          {content.storefrontImage &&
            <GatsbyImage
              image={content.storefrontImage.gatsbyImageData}
            />
          }
        </Container>
        <Container size='lg' className='mt-4 relative'>
          <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3 text-md'>
            <div>
              <div className='CircleNum'>
                1
              </div>
              <h3 className='mt-3 mb-2'>
                Deal-building tools
              </h3>
              <p>
                Empower customers to build their own deal seamlessly, whether at home or in your showroom, with guidance from your agents.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                2
              </div>
              <h3 className='mt-3 mb-2'>
                Multiple payment options
              </h3>
              <p>
                Show customers exactly what they’ll owe for their vehicle (including taxes, fees and discounts applied), and let them choose how they want to pay for it.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                3
              </div>
              <h3 className='mt-3 mb-2'>
                Easy trade valuation
              </h3>
              <p>
                Allow customers to factor their trade-in value into the deal using Roadster’s Express Trade® or a third-party trade solution of your choice*.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                4
              </div>
              <h3 className='mt-3 mb-2'>
                Secure credit application
              </h3>
              <p>
                Enable shoppers to submit a credit application from the convenience of their computer or mobile device and upload photos of their driver’s license and insurance card. Everything’s encrypted and secured by two-factor authentication.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                5
              </div>
              <h3 className='mt-3 mb-2'>
                Service and protection plans
              </h3>
              <p>
                Drive more revenue by prominently promoting your Service and protection plans throughout the shopping experience.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                6
              </div>
              <h3 className='mt-3 mb-2'>
                Optional accessories store
              </h3>
              <p>
                Entice shoppers with add-ons — from cargo nets to cross bars — and watch your profits grow.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                7
              </div>
              <h3 className='mt-3 mb-2'>
                Sales agent tools
              </h3>
              <p>
                Enable your internet and BDC teams to share interactive deal sheets with customers who ask about pricing via phone or email, or who are soon to be off-lease.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                8
              </div>
              <h3 className='mt-3 mb-2'>
                Vehicle Recommender
              </h3>
              <p>
                Use this showroom tool to assess customers needs, provide vehicle comparisons and help them decide on exactly the right ride.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                9
              </div>
              <h3 className='mt-3 mb-2'>
                Deal management tools
              </h3>
              <p>
                Communicate with customers, receive their credit application and trade-in information, and manage the entire deal jacket with ease and efficiency.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                10
              </div>
              <h3 className='mt-3 mb-2'>
                Free marketing assets
              </h3>
              <p>
                Drive shoppers to your Express Storefront with our provided marketing assets that are completely customized to your brand.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                11
              </div>
              <h3 className='mt-3 mb-2'>
                Integrated Checkout
              </h3>
              <p>
                Enable customers to start structuring their deal without leaving your website, and then click to the Express Storefront to take the next steps.
              </p>
            </div>
            {content.checkoutImage &&
              <div className='self-center'>
                <GatsbyImage
                  image={content.checkoutImage.gatsbyImageData}
                  alt=''
                />
              </div>
            }
            <div>
              <div className='CircleNum'>
                12
              </div>
              <h3 className='mt-3 mb-2'>
                Self-guided learning
              </h3>
              <p>
                Educate your team about the ins and outs of our Express Storefront with Roadster Academy. Our self-guided learning portal features multimedia courses and quizzes designed for each role within your dealership, so everyone can become “Express Storefront Certified” before the training day.
              </p>
            </div>
            <div>
              <div className='CircleNum'>
                13
              </div>
              <h3 className='mt-3 mb-2'>
                Expert support
              </h3>
              <p>
                Get the expert guidance you need to succeed at each step. Your dedicated Dealer Success Manager will lead your team through an extensive onsite training during launch, and help you evolve your processes and optimize your results every few weeks thereafter.
              </p>
            </div>
          </div>
          <div className='mt-3 sm:mt-5 text-center text-gray text-base font-regular mx-auto' style={{ maxWidth: '60em' }}>
            {content.coOp?.childMdx?.body &&
              <MDXRenderer children={content.coOp.childMdx.body} />
            }
            <p>
              *The following third-party trade solutions are available for integration: AutoHub, Edmunds TMV, Kelley Blue Book ICO, Kelley Blue Book Trade-In Value, TradePending, TrueCar.
            </p>
          </div>
          <h2 className='RuleHed my-4 sm:my-5'>
            Related Products
          </h2>
          <div className='grid gap-4 sm:gap-5'>
            {content.tradeImage &&
              <div className='grid gap-3 sm:gap-0 sm:grid-cols-2 items-center'>
                <div className='sm:order-last'>
                  <GatsbyImage
                    image={content.tradeImage.gatsbyImageData}
                  />
                </div>
                <div className='text-md'>
                  <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1'>
                    Express Trade<sup className='tm'>®*</sup>
                  </h3>
                  <p>
                    Roadster’s proprietary trade tool enables customers to use mobile-friendly technology to easily capture and submit their trade-in information to you. It gives you complete control over the value of the vehicle, and streamlines the process so you can make your customer a firm offer in minutes.
                  </p>
                  <p className='mb-0 mt-3 text-base text-gray'>
                    *Available for purchase as an Express Storefront add-on.
                  </p>
                </div>
              </div>
            }
            {content.responseImage &&
              <div className='grid gap-3 sm:gap-0 sm:grid-cols-2 items-center'>
                <div>
                  <GatsbyImage
                    image={content.responseImage.gatsbyImageData}
                  />
                </div>
                <div className='text-md'>
                  <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1'>
                    Express Response<sup className='tm'>™*</sup>
                  </h3>
                  <p>
                    Express Response automatically sends quality, actionable emails to internet leads that are proven to engage them 10x more than generic CRM auto-responders. Complete with the pricing and VIN information customers want, these messages invite them to immediately begin building their deal online.
                  </p>
                  <p className='mb-0 mt-3 text-base text-gray'>
                    *Available for purchase as an Express Storefront add-on.
                  </p>
                </div>
              </div>
            }
            {content.liveImage &&
              <div className='grid gap-3 sm:gap-0 sm:grid-cols-2 items-center justify-center'>
                <div className={content.tradeImage && content.responseImage ? 'sm:order-last' : ''}>
                  <GatsbyImage
                    image={content.liveImage.gatsbyImageData}
                  />
                </div>
                <div className='text-md'>
                  <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1 '>
                    Express Store Live<sup className='tm'>™*</sup>
                  </h3>
                  <p>
                    Express Store Live leverages the in-depth knowledge of Roadster's automotive experts and the comprehensive features of your Express Store to engage customers in conversations that lead to higher quality opportunities. When we answer customers’ initial questions, <span className='font-semi-bold'>85.5%</span> continue chatting with us, with the average Express Store Live conversation lasting 26 minutes — <span className='font-semi-bold'>2x the industry average</span>.<sup>1</sup>
                  </p>
                  <p className='mb-0 mt-3 text-base text-gray'>
                    *Available for purchase as an Express Storefront add-on.
                    <br />
                    1. Average industry chat length is 11 minutes. Source: 2020 Interactive Customer Service Report, “LiveChat.”
                  </p>
                </div>
              </div>
            }
          </div>
        </Container>
      </div>
      {content.testimonial &&
        <div className='Section Section--dark'>
          {content.testimonial.image &&
            <GatsbyImage
              image={content.testimonial.image.gatsbyImageData}
              className='absolute inset-0'
            />
          }
          <div className='bg-darken5 absolute inset-0' />
          <Container className='text-center leading-relaxed relative'>
            <div className='font-serif italic'>
              <div className='font-semi-bold leading-none' style={{ fontSize: '6rem' }}>“</div>
              {content.testimonial.quote?.childMdx?.body &&
                <div className='text-lg italic mx-auto' style={{ maxWidth: '46em' }}>
                  <MDXRenderer children={content.testimonial.quote.childMdx.body} />
                </div>
              }
            </div>
            {content.testimonial.name &&
              <div className='mt-3 text-md'>
                {content.testimonial.name}
              </div>
            }
            {content.testimonial.title &&
              <div className='text-md font-semi-bold'>
                {content.testimonial.title}
              </div>
            }
          </Container>
        </div>
      }
      {content.video &&
        <div className='Section'>
          <Container size='lg'>
            <div className='grid gap-4 sm:gap-5 sm:grid-cols-12'>
              <div className='sm:col-span-5 self-center'>
                {content.video.quoteName &&
                  <div className='text-xl font-light'>
                    {content.video.quoteName}
                  </div>
                }
                {content.video.quoteTitle &&
                  <div className='uppercase tracking-wide mb-3'>
                    {content.video.quoteTitle}
                  </div>
                }
                {content.video.quote?.childMdx?.body &&
                  <div className='font-serif italic text-lg'>
                    <MDXRenderer children={content.video.quote.childMdx.body} />
                  </div>
                }
                {content.video.videoId &&
                  <div className='mt-3'>
                    <VideoModal videoId={content.video.videoId}>
                      <Button
                        variant='dark'
                        children='Watch Video'
                      />
                    </VideoModal>
                  </div>
                }
              </div>
              <div className='sm:col-span-7'>
                {content.video.videoId &&
                  <VideoModal videoId={content.video.videoId}>
                    <button type='button' className='block relative'>
                      {content.video.poster &&
                        <GatsbyImage
                          image={content.video.poster.gatsbyImageData}
                        />
                      }
                      <StaticImage
                        alt='Play Video'
                        src='../../images/icon-play.png'
                        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:opacity-60 transition-opacity duration-300'
                        placeholder='tracedSVG'
                        style={{ width: '12%' }}
                      />
                    </button>
                  </VideoModal>
                }
              </div>
            </div>
          </Container>
        </div>
      }
      <div className='Section Section--dark'>
        <Container>
          <DemoForm invert={true} />
        </Container>
      </div>
    </Layout>
  )
}

export default OemProgramPage

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulOemProgram(slug: {eq: $slug}) {
      metaDescription
      caseStudyLink
      brand
      checkoutImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      responseImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      storefrontImage {
        gatsbyImageData(placeholder: BLURRED, width: 700)
      }
      testimonial {
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
        title
        quote {
          childMdx {
            body
          }
        }
      }
      coOp {
        childMdx {
          body
        }
      }
      video {
        quote {
          childMdx {
            body
          }
        }
        quoteName
        quoteTitle
        videoId
        name
        title
        poster {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      intro {
        childMdx {
          body
        }
      }
      liveImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      slug
      title
      tradeImage {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
